import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import firebase from "firebase";
import VueSweetalert2 from "vue-sweetalert2";
import "firebase/auth";

//Editor
import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor);

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";


// QR codes
import VueQRCodeComponent from "vue-qrcode-component";
Vue.component("qr-code", VueQRCodeComponent);

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDKZB7JEb703hK2xQkQkW9z4wCfY1Lewg4",
  authDomain: "waldent-digital-signage.firebaseapp.com",
  projectId: "waldent-digital-signage",
  storageBucket: "waldent-digital-signage.appspot.com",
  messagingSenderId: "8687522599",
  appId: "1:8687522599:web:3036661c5e2e2a6ab2cf02",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
Vue.use(VueSweetalert2);

Vue.config.productionTip = false;

// eslint-disable-next-line no-unused-vars
let app;

firebase.auth().onAuthStateChanged(async function() {
  const user = firebase.auth().currentUser;
  if (user && router.currentRoute.path === "/") {
    router.push("/Dashboard");
  }

  new Vue({
    router,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
  app = true;
});
